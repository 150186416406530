<template>
  <div id="report-list" class="rounded bg-white shadow">
    <ReportSidebar :update="update"/>
    <div class="p-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start gap-2 mb-1 mb-md-0">
        </b-col>

        <b-col cols="12" md="6" class="d-flex gap-2 justify-content-end">
          <BInputGroup style="max-width:350px">
            <FlatPickr v-model="dateRange" class="h-100 form-control border-primary" :config="{ mode: 'range', maxDate:'today', onClose:updateFilterDate}" 
              placeholder="Date range"  />
            <template #prepend>
              <BButton variant="outline-primary"><fa-icon :icon="['fad','calendar-alt']" /></BButton>
            </template>
            <div class="input-group-append" @click.stop="clearFilterDate">
              <b-button variant="outline-primary"><fa-icon icon="times" /></b-button>
            </div>
          </BInputGroup>
        </b-col>
      </b-row>
    </div>

    <div>
      <q-timeline v-if="reportsData.days" layout="comfortable" class="less-comfort">
        <q-timeline-entry v-for="(day, i) of reportsData.days" :key="i" :subtitle="`${new Date(day.from).toDateString()}`" 
          :color="routesByDay(day, reportsData.routes).length?'primary':'secondary'" :heading="Boolean(day.heading)">
          <template v-if="day.heading">{{new Date(day.heading).toLocaleString('en-SG', { month: 'long', year: "numeric"})}}</template>
          <div v-if="!day.heading" v-for="route in routesByDay(day, reportsData.routes)" :key="route._id" class="d-flex flex-nowrap"><!-- Loop by routes-->
            <div class="d-flex align-items-center border bg-primary text-white" style="border-radius: 2rem 0 0 2rem">
              <div class="text-center px-1">
                <span class="text-nowrap">{{route.name}}</span><br>{{checkPointInRoute(day, route, reportsData.clockings).filter(check=>check.checked).length}} / {{route.points.length}}
              </div>              
            </div>
            <div class="d-flex flex-wrap">
              <div v-for="(check,i) of checkPointInRoute(day, route, reportsData.clockings)" :key="i" :class="check.checked?'bg-success':'bg-danger'" class="d-flex align-items-center p-1 border text-white">
                <template v-if="check.checked">{{check.time | date}}<br>{{check.time | time}}</template><fa-icon v-else icon="times" />
              </div>
            </div>
              
          </div>
        </q-timeline-entry>
      </q-timeline>
    </div>

    <div class="p-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18" /></template>
            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
          </b-pagination>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BInputGroup, BButton, BRow, BCol, BPagination,
  BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FlatPickr from 'vue-flatpickr-component'

import { ref, watch } from '@vue/composition-api'
import { fileUrl, date, time } from '@core/utils/filter'
import { Toast, Dialog } from '@core/utils/other'
import store from '@/store'
import useReport from './useReport'
import ReportSidebar from './ReportSidebar'

export default {
  name: 'report-list',
  components: {
    BFormInput, BInputGroup, BButton, BRow, BCol, BPagination,
    BListGroup, BListGroupItem,

    vSelect,
    //VueSelect,

    ReportSidebar,
    FlatPickr
  },
  props:{
    showIcon:{
      type: Boolean,
      default: false
    },
    selectable:{
      type: Boolean,
      default: false
    }
  },
  filters:{
    date, time
  },
  setup() {

    const {
      // Sidebar
      reportId,
      // Actions
      fetchReports,reportsData,
      disableReport,
      fetchReport,
      refetchData,
      

      // Table items
      refReportListTable,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,
      // Filter
      filterInputs,
      filterSelects,
      filterTable, filterTableOptions,

      reportFilters
    } = useReport()

    // Date filter
    const dateRange = ref(null)
    const updateFilterDate = function (selectedDates){
      let from = selectedDates[0]
      let to = selectedDates[1]
      if(!from || !to){
        // dateRange.value=null
        // reportFilters.value.from=null
        // reportFilters.value.to=null
        return 
      }
      
      //是否超过31天
      if (to.getTime() - from.getTime() > 31*24*3600*1000) return Dialog(false).fire({title:'Date range cannot be over 31 days'})
      reportFilters.value.from = from
      reportFilters.value.to = to

      refetchData()

      // 生成周期数据 days
      reportsData.value.days = []
      for (let day = new Date(from).setHours(0,0,0,0), month; day <= new Date(to).setHours(23,59,59,999); day += 24*3600*1000){
        if(new Date(day).getMonth() !== month) reportsData.value.days.push({heading: day})
        month = new Date(day).getMonth()
        reportsData.value.days.push({
          from: new Date(day), 
          to: new Date(day + 24*3600*1000-1),
          //time: new Date(day),
          year: new Date(day).getFullYear(),
          month: new Date(day).getMonth()+1,
          date: new Date(day).getDate(),
          day: new Date(day).getDay(),
        })
      }
    }

    const clearFilterDate = function(){
      dateRange.value=null
      reportFilters.value.from=null
      reportFilters.value.to=null
      reportsData.value = {days:[]}
    }

    const routesByDay = function(day, routes){
      // day={year: Int, month:Int, date:Int, day:Int, from:Obj, to:Ob, time:Date}
      // routes = [{_id, name, start:String, end:String, date:[Int], day[Int], time:[{from, to}], points: []}]
      if(!day) return [] //不是有效日期
      if(!routes || routes.length===0) return [] //没有route

      const routeData = routes.map(route=>{
        // 在 start 和 end 之间
        if (route.start && day.to < new Date(route.start)) return false
        if (route.end && day.from > new Date(route.end)) return false
        // Date 每月的某一天
        if (route.date && route.date.length>0 && !route.date.includes(day.date)) return false
        // Day 每周的某一天
        if (route.day && route.day.length>0 && !route.day.includes(day.day)) return false
  
        // TODO unwind time?

        return route
      }).filter(route => route !== false)
      if (routeData.length===0) return []

      return routeData
    }

    const checkPointInRoute = function(day, route, clockings){
      if(!route || route.length===0) return []
      // 符合当前日期的clockings
      const data = clockings.filter(clocking => clocking && clocking._id && clocking._id.year === day.year && clocking._id.month===day.month && clocking._id.day===day.date)
      const clocking_array = data.length===0?[]: data[0].items

      // 如果route有time限制
      if(route.time && route.time.from) clocking_array.filter(item => (new Date(item.time).getHours()*3600 + new Date(item.time).getMinutes()*60 + new Date(item.time).getSeconds()) >= route.time.from)
      if(route.time && route.time.to) clocking_array.filter(item => (new Date(item.time).getHours()*3600 + new Date(item.time).getMinutes()*60 + new Date(item.time).getSeconds()) <= route.time.to)

      // clocking array=>obj
      const clocking_obj = {}
      clocking_array.forEach(item=>{
        clocking_obj[item.point] = item.time
      })

      // 在route的points上标记  
      return route.points.map(point => ({point, checked: Boolean(clocking_obj[point]), time: clocking_obj[point]}))
    }

    // sidebar
    const update = ref(null) // true for [update, add]
    watch(reportId, val=>{ //放在这里避免多次请求
      if(val) fetchReport(val)
    })

    watch(()=>store.state.report.reportListToUpdate, val=>{
      if(val)refReportListTable.value.refresh()
    })

    const openSidebar = function(type, report){
      if (type==='add'){
        update.value = true
        store.commit('report/SET_REPORT_ID', '')
        store.commit('report/TOGGLE_SIDEBAR', true)
      } else if (type==='update') {
        if (!report ||!report._id) return 
        update.value = true
        store.commit('report/SET_REPORT_ID', report._id)
        store.commit('report/TOGGLE_SIDEBAR', true)
      } else if(type==='view'){
        if (!report ||!report._id) return 
        update.value = false
        store.commit('report/SET_REPORT_ID', report._id)
        store.commit('report/TOGGLE_SIDEBAR', true)
      }
    }

    return {
      // Actions
      disableReport,
      fetchReports,
      refetchData,reportsData,

      routesByDay, // 符合日期的routes
      checkPointInRoute, //符合日期的routes结果

      // Date filter
      dateRange, updateFilterDate, clearFilterDate,
      reportFilters,
      
      // Sidebar
      update,
      openSidebar,

      refReportListTable,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,  
      // Table filters
      filterInputs,
      filterSelects,
      filterTable, filterTableOptions,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,

      // Filter
      fileUrl, date
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filter-table-selector{
  min-width: 90px
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#report-list {
  .less-comfort.q-timeline--comfortable {
    .q-timeline__subtitle {
      width: 10%;
      min-width: 180px;
    }
  }
}
</style>
