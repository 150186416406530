import axios from '@axios'

export default {
  namespaced: true,
  state: {
    reportListToUpdate: false,
    isSidebarActive: false,
    reportId: '',
    reportData: {files:[]},
    reportFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_REPORT_LIST_UPDTAE(state, val){
      state.reportListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_REPORT(state, val){
      state.reportId = '',
      state.reportData = {files:[]},
      state.reportFiles = []
    },
    SET_REPORT_ID(state, val){
      state.reportId = val
    },
    SET_REPORT_DATA(state, data){
      state.reportData = data
      state.reportFiles = data.files
    }
  },
  actions: {
    fetchReports({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/security/report', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_REPORT_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchReport({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/security/report/${id}`)
          .then(response => {
            commit('SET_REPORT_DATA', response.data.report)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateReport(ctx, {id, report}) {
      return new Promise((resolve, reject) => {
        axios.post(`/security/report/${id||''}`, { ...report })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableReport(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/security/report/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/security/report/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/report/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
